/**
 * Эта функция грузит важные для первичного отображения ресурсы параллельно с основным чанком приложения
 * Импорты не из индексов пакетов специально, так как пока tree-shaking не работает и тянется куча ненужного хлама
 * При любом изменении лучше чекнуть что размер этого чанка не увеличился (сейчас примерно 7kb)
 */
import { defineLocale } from '@wl/i18n/lib/defineLocale'
import { dynamicLocaleImport } from '@wl/i18n/lib/dynamicLocaleImport'
import {
  languages,
  allowedActualLanguagesSortedByPriority,
} from '@wl/core/instanceConfig/languages'
import type { GetInitResponseBody } from '@wl/api'
import { httpClient } from '@wl/http-client/lib/httpClient'
import { loadLocale } from '@wl/i18n/date/loadLocale'

export interface PrefetchedResources {
  /**
   * Запрос GET init/v2 - системные настройки и т.д.
   */
  init?: Promise<GetInitResponseBody>
  /**
   * Бинарный актуал для главной страницы
   */
  binaryMainPageActual?: Promise<ArrayBuffer>
  /**
   * Переводы для i18n
   */
  localeMessages?: ReturnType<typeof dynamicLocaleImport>
  /**
   * Переводы для dayjs
   */
  dateLocaleMessages?: Promise<any>
}

async function fetchInit() {
  return httpClient(`${ENV.API.HTTP[0]}/init/v2`, {
    credentials: 'include',
  }).json() as Promise<GetInitResponseBody>
}

async function fetchMainPageActual(locale: string) {
  return httpClient(
    `${ENV.SPORTSBOOK.BINARY}/sb-actual/main-page?version=3&language=${locale}`,
    {
      credentials: 'omit',
    },
  ).arrayBuffer()
}

function isHomePage(platform: 'mobile' | 'desktop') {
  if (platform === 'mobile') {
    return ['/mobile', '/mobile/'].includes(window.location.pathname)
  } else {
    return ['/', ''].includes(window.location.pathname)
  }
}

export function prefetchCriticalResources(
  platform: 'mobile' | 'desktop',
): PrefetchedResources {
  const locale = defineLocale(languages)
  const actualLocale = allowedActualLanguagesSortedByPriority.includes(locale)
    ? locale
    : (allowedActualLanguagesSortedByPriority.find(
        (otherLocale) => otherLocale !== locale,
      ) ?? 'en')

  return {
    init: fetchInit(),
    localeMessages: dynamicLocaleImport(locale),
    binaryMainPageActual: isHomePage(platform)
      ? fetchMainPageActual(actualLocale)
      : undefined,
    dateLocaleMessages: loadLocale(locale),
  }
}
